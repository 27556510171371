import { entries } from '../types/entries'
import { Pagination, PaginationQuery } from '../types/types'
import axiosInstance from './axiosInstance'

export const listEntriesByClient = async ({
  id,
  params,
  token
}: {
  id: string
  params: PaginationQuery
  token?: string
}): Promise<Pagination<entries>> => {
  try {
    const headers = token
      ? {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      : {}
    const response = await axiosInstance.get(`/api/entries/${id}`, {
      params,
      ...headers
    })

    return response.data
  } catch (error) {
    console.error('Error en la solicitud de la lista de equipos:', error)
    throw error
  }
}

export const validate_qr_recepcion = async ({
  params,
  token
}: {
  params: { code: string; clientID: string; camera: string }
  token?: string
}): Promise<entries> => {
  try {
    const headers = token
      ? {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      : {}
    const response = await axiosInstance.post(
      `/api/entries/validate_qr_recepcion`,
      JSON.stringify(params),
      { ...headers }
    )

    return response.data.entry
  } catch (error) {
    console.error('Error no se encuentra qr', error)
    throw error
  }
}

type paramsReport = {
  startDate?: string
  endDate?: string
  name?: string
  lastName?: string
  DNI?: string
  destination?: string
  type?: string
}
export const getDataReport = async ({
  id,
  params,
  token
}: {
  id: string
  params: paramsReport
  token?: string
}): Promise<Pagination<entries>> => {
  try {
    const headers = token
      ? {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      : {}
    const response = await axiosInstance.get(`/api/entries/reports/${id}`, {
      params,
      ...headers
    })

    return response.data
  } catch (error) {
    console.error('Error en la solicitud de la lista de equipos:', error)
    throw error
  }
}
export const generateReport = async ({
  id,
  params,
  token
}: {
  id: string
  params: paramsReport
  token?: string
}): Promise<{ url: string }> => {
  try {
    const headers = token
      ? {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      : {}
    const response = await axiosInstance.get(`/api/entries/generate_reports/${id}`, {
      params,
      ...headers
    })

    return response.data
  } catch (error) {
    console.error('Error en la solicitud de la lista de equipos:', error)
    throw error
  }
}


export const generateReportRecluse = async ({
  id,
  params,
  token
}: {
  id: string
  params: paramsReport
  token?: string
}): Promise<{ url: string }> => {
  try {
    const headers = token
      ? {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      : {}
    const response = await axiosInstance.get(`/api/entries/generate_reports_recluse/${id}`, {
      params,
      ...headers
    })

    return response.data
  } catch (error) {
    console.error('Error en la solicitud de la lista de equipos:', error)
    throw error
  }
}




export const approve_entry = async ({
  id,
  token
}: {
  id: string
  token?: string
}): Promise<{ url: string }> => {
  try {
    const headers = token
      ? {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      : {}
    const response = await axiosInstance.post(
      `/api/entries/approve_entry/${id}`,
      {},
      {
        ...headers
      }
    )

    return response.data
  } catch (error) {
    console.error('Error en la solicitud', error)
    throw error
  }
}

export const modify_entry = async ({
  id,
  status,
  token
}: {
  id: string
  status: string
  token?: string
}): Promise<{ url: string }> => {
  try {
    const headers = token
      ? {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      : {}
    const response = await axiosInstance.post(
      `/api/entries/modify_entry/${id}/${status}`,
      {},
      {
        ...headers
      }
    )

    return response.data
  } catch (error) {
    console.error('Error en la solicitud', error)
    throw error
  }
}

export const DownloadPdfOkId = async (IdPdfOkid: string, token?: string): Promise<any> => {
  try {
    const headers = token
      ? {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      : {}
    const response = await axiosInstance.get(`/api/entries/okid/${IdPdfOkid}`, {
      ...headers
    })

    return response.data
  } catch (error) {
    console.error('Error en la solicitud de la lista de equipos:', error)
    throw error
  }
}
