import React, { useEffect, useState } from 'react'
import { useLoading } from '../../Provider/LoadingProvider'
import Table, { TableColumn, enumTypeColumn } from '../../components/Table'
import { entries } from '../../types/entries'
import { Pagination } from '../../types/types'
import './style.scss'
import { DoneAll, HighlightOff, PictureAsPdf } from '@mui/icons-material'

import { ReactComponent as OkidDead } from '../../assets/svg/OkidDead.svg'
import { ReactComponent as lowRF } from '../../assets/svg/lowRF.svg'
import { ReactComponent as CheckDpi } from '../../assets/svg/CheckDpi.svg'

import { Fade, IconButton } from '@mui/material'
import { columnsCheckInOkId } from '../../components/Tables/CheckIn/columnsOkid'
import { DownloadPdfOkId, generateReportRecluse } from '../../services/entries'

import AutoComplete from '../../components/Form/AutoComplete'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment-timezone'
import { useAuth } from '../../Provider/AuthProvider'
import Button from '../../components/Form/Button'
import InputDark from '../../components/Form/InputDark'
import RangePicker from '../../components/Form/RangePicker'
import { generateReport, getDataReport } from '../../services/entries'
import { convertEntriesAttributes } from '../../utils/utils'
import { ConfigProvider, Select as SelectAnt } from 'antd'
import { faEraser, faFileExcel, faFilePdf, faSearch } from '@fortawesome/free-solid-svg-icons'
import Select from '../../components/Form/Select'
import { listBrandsAll } from '../../services/brand'
import { brand } from '../../types/brand'
import dayjs from 'dayjs'
import { listByDestinationCode } from '../../services/resident'
import { typeQrEnumString } from '../../types/qr'
import FilesWachs from '../../components/CheckIn/SocketNotifications/filesWachs'
import { LightTooltip } from '../../components/tooltips/text'
import Icon from '@ant-design/icons'
import Modal from '../../components/GeneralComponents/Modal'
import { SelectProps } from 'antd/lib'

const ReportsPage = () => {
  const [startDate, setStartDate] = useState<dayjs.Dayjs | null>(dayjs().startOf('day'))
  const [endDate, setEndDate] = useState<dayjs.Dayjs | null>(dayjs().endOf('day'))
  const { user, token } = useAuth()

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [pdfUrl, setPdfUrl] = useState('')

  const [data, setData] = useState<Pagination<entries>>({
    currentPage: 1,
    hasNextPage: false,
    hasPrevPage: false,
    total: 1,
    totalPages: 1,
    data: []
  })
  const [brands, setBrands] = useState<brand[]>([])
  const [formData, setFormData] = useState<any>({
    destinationCode: ''
  })
  const [optionsDestinations, setOptionsDestinations] = useState<
    { label: string; value: string }[]
  >([])
  const [searchedValue, setSearchedValue] = useState<string>()
  const { startLoading, stopLoading } = useLoading()

  const getDataCodes = async () => {
    try {
      if (!searchedValue) return
      const values = await listByDestinationCode({
        search: searchedValue,
        clientId: user?.clientId as string
      })
      setOptionsDestinations(
        values.map(e => ({
          label: `${e.destinationCode} ${e.name} ${e.lastName}`,
          value: e.destinationCode
        }))
      )
    } catch (error) {
      console.error(error)
    }
  }
  const values = [
    {
      label: 'Nombres',
      value: 'name'
    },
    {
      label: 'Apellidos',
      value: 'lastName'
    },
    {
      label: 'DNI',
      value: 'DNI'
    }
  ]
  const handleChange = (name: string, value: string | boolean | string[]) => {
    setFormData((prevData: any) => ({ ...prevData, [name]: value }))
  }
  const onSearch = async (page?: number) => {
    try {
      startLoading()

      const startDateG = moment(startDate?.toDate()).format('YYYY-MM-DDTHH:mm:ss')
      const endDateG = moment(endDate?.toDate()).format('YYYY-MM-DDTHH:mm:ss')

      const resp = await getDataReport({
        id: user?.clientId as string,
        token: token as string,
        params: {
          startDate: startDate ? startDateG : null,
          endDate: endDate ? endDateG : null,
          page: page || data.currentPage,
          ...formData
        }
      })
      setData({ ...resp, data: resp.data.map(convertEntriesAttributes) })
    } catch (error) {
      console.error(error)
    } finally {
      stopLoading()
    }
  }
  const onGenerate = async (typeDocument: 'pdf' | 'excel') => {
    try {
      startLoading()

      const startDateG = moment(startDate?.toDate()).format('YYYY-MM-DDTHH:mm:ss')
      const endDateG = moment(endDate?.toDate()).format('YYYY-MM-DDTHH:mm:ss')

      const value = await generateReport({
        id: user?.clientId as string,
        token: token as string,
        params: {
          startDate: startDate ? startDateG : null,
          endDate: endDate ? endDateG : null,
          ...formData,
          typeDocument
        }
      })

      // Hacer fetch para obtener el archivo
      const response = await fetch(
        `${process.env.REACT_APP_API_URL_BACKEND}/report/${value.url}`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      const blob = await response.blob()
      // Crea un enlace para ver el PDF
      const url = URL.createObjectURL(blob)
      setPdfUrl(url)

      // Abre el modal
      typeDocument === 'pdf' && setIsModalVisible(true)

      if (typeDocument === 'excel') {
        const a = document.createElement('a')
        a.style.display = 'none'
        a.href = url
        a.download = value.url
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)

        // Liberar el objeto URL
        window.URL.revokeObjectURL(url)
      }
    } catch (error) {
      console.error(`error al crear reporte`, error) // Maneja el error
    } finally {
      stopLoading()
    }
  }

  const RecluseReport = async () => {
    try {
      startLoading()

      const startDateG = moment(startDate?.toDate()).format('YYYY-MM-DDTHH:mm:ss')
      const endDateG = moment(endDate?.toDate()).format('YYYY-MM-DDTHH:mm:ss')

      const value = await generateReportRecluse({
        id: user?.clientId as string,
        token: token as string,
        params: {
          startDate: startDate ? startDateG : null,
          endDate: endDate ? endDateG : null,
          ...formData
        }
      })

      // Hacer fetch para obtener el archivo
      const response = await fetch(
        `${process.env.REACT_APP_API_URL_BACKEND}/report/${value.url}`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      const blob = await response.blob()
      // Crea un enlace para ver el PDF
      const url = URL.createObjectURL(blob)
      setPdfUrl(url)

      // Abre el modal

      const a = document.createElement('a')
      a.style.display = 'none'
      a.href = url
      a.download = value.url
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)

      // Liberar el objeto URL
      window.URL.revokeObjectURL(url)
    } catch (error) {
      console.error(`error al crear reporte`, error) // Maneja el error
    } finally {
      stopLoading()
    }
  }

  const getBrands = async () => {
    const brands = await listBrandsAll(token)
    setBrands(brands)
  }
  const handleChangeText = (value: string) => {
    setSearchedValue(value)
  }
  const handleSearch = () => {
    setData(prevData => ({ ...prevData, currentPage: 1 }))
    onSearch(1)
  }
  const handleClear = () => {
    setFormData({
      destinationCode: ''
    })
    setStartDate(null)
    setEndDate(null)
    setSearchedValue(undefined)
  }

  useEffect(() => {
    getBrands()
  }, [])
  useEffect(() => {
    if (searchedValue && searchedValue.length) {
      getDataCodes()
    }
  }, [searchedValue])

  const handleModalClose = () => {
    setIsModalVisible(false)
    setPdfUrl('')
  }

  const Actions = ({ id, item }: { id: string; item?: entries }) => {
    return (
      <>
        {item?.OKIDNumber && (
          <IconButton onClick={() => handlePdfOkId(item?.OKIDNumber)}>
            <PictureAsPdf style={{ color: 'red' }} />
          </IconButton>
        )}
      </>
    )
  }

  const svgOkid = (item: entries | undefined) => {
    const icons = [] // Array para acumular los íconos

    if (item?.okid) {
      const dataOkid = parseInt(item?.okid)
      const RF = item.trm ? parseInt(item?.trm) : 0
      const PORCET_OK = parseInt(process.env.REACT_APP_API_MIN_PORCET_OK!)
      const PORCET_RF = parseInt(process.env.REACT_APP_API_MIN_PORCET_RF!)

      // Verificaciones y acumulación de íconos
      if (!item.DNI) {
        icons.push(
          <LightTooltip title={'▪️ DPI NO ENCONTRADO'} TransitionComponent={Fade}>
            <HighlightOff
              key="no-dni"
              style={{ color: 'red', fontSize: '2rem' }}
              className="alertOkid"
            />
          </LightTooltip>
        )
      }
      if (item.FECHA_DEFUNCION) {
        icons.push(
          <LightTooltip title={'▪️ IDENTIDAD FALLECIDA'} TransitionComponent={Fade}>
            <Icon
              key="defuncion"
              component={OkidDead}
              style={{ fontSize: '2rem' }}
              className="alertOkidDead"
            />
          </LightTooltip>
        )
      }
      if (dataOkid <= PORCET_OK) {
        icons.push(
          <LightTooltip title={'▪️ ANALIZAR DPI'} TransitionComponent={Fade}>
            <Icon
              key="low-okid"
              component={CheckDpi}
              style={{ fontSize: '2rem' }}
              className="alertOkidDead"
            />
          </LightTooltip>
        )
      }
      if (RF <= PORCET_RF) {
        icons.push(
          <LightTooltip title={'▪️ RECONOCIMIENTO FACIAL BAJO'} TransitionComponent={Fade}>
            <Icon
              key="low-rf"
              component={lowRF}
              style={{ fontSize: '2rem' }}
              className="alertOkidDead"
            />
          </LightTooltip>
        )
      }

      // Si no hay alertas, se agrega un ícono de estado "ok"
      if (icons.length === 0) {
        icons.push(
          <LightTooltip title={'✔️ CODIGO 10 10'} TransitionComponent={Fade}>
            <DoneAll key="ok" style={{ color: 'green', fontSize: '2rem' }} />
          </LightTooltip>
        )
      }
    }

    return <div className="flex-container">{icons}</div> // Retorna todos los íconos dentro de un contenedor div
  }

  const showAProved: TableColumn[] = [
    ...columnsCheckInOkId,
    {
      title: 'ESTADOS',
      dataIndex: 'approved',
      key: 'approved',
      type: enumTypeColumn.custom,
      CustomRender: ({ item }: { id: string; item?: entries }) => {
        return (
          <>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '30px',
                gap: '0.5rem'
              }}
            >
              {item && svgOkid(item)}
            </div>
          </>
        )
      }
    }
  ]

  const handlePdfOkId = async (id?: string) => {
    if (id) {
      try {
        startLoading()
        console.log(id)
        const dataPdf64 = await DownloadPdfOkId(id, token) // Pedimos el base64 del pdf

        // Convierte el base64 en un objeto Blob
        const pdfBlob = base64ToBlob(dataPdf64?.pdf64, 'application/pdf')

        // Crea un enlace para ver el PDF
        const url = URL.createObjectURL(pdfBlob)
        setPdfUrl(url)

        // Abre el modal
        setIsModalVisible(true)
      } catch (error) {
        console.error('Error al crear reporte', error) // Maneja el error
      } finally {
        stopLoading()
      }
    }
  }

  const base64ToBlob = (base64: string, contentType: string) => {
    const byteCharacters = atob(base64)
    const byteNumbers = new Array(byteCharacters.length)
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers)
    return new Blob([byteArray], { type: contentType })
  }

  const options = [
    { value: 'NODPI', label: 'DPI NO ENCONTRADO' },
    { value: 'FALLECIDO', label: 'IDENTIDAD FALLECIDA' },
    { value: 'LOWOKID', label: 'ANALIZAR DPI' },
    { value: 'LOWRF', label: 'RECONOCIMIENTO FACIAL BAJO' },
    { value: '10_10', label: 'CODIGO 10 10' }
  ]

  const sharedProps: SelectProps = {
    mode: 'multiple',
    options,
    style: { width: '100%', height: '100%' },
    maxTagCount: 'responsive',
    size: 'large'
  }

  return (
    <div className="reportPage">
      <div className="containerTitle">
        <div className="title">Consultar Registros</div>
      </div>
      <div className="containerFilters">
        <div className="containerInputFilters">
          <RangePicker
            value={{
              start: startDate,
              end: endDate
            }}
            onChange={({ start, end }) => {
              if (start) {
                setStartDate(start)
              }
              if (end) {
                setEndDate(end)
              }
            }}
          />
          {values.map(e => (
            <div key={e.value} className="inputDark">
              <InputDark
                placeholder={e.label}
                value={formData ? formData[e?.value] ?? '' : ''}
                onChange={value => handleChange(e?.value, value.target.value)}
              />
            </div>
          ))}
          <ConfigProvider
            theme={{
              components: {
                Select: {
                  colorBgContainer: 'rgba(56, 78, 183, 0.3)',
                  colorBorder: '#484747',
                  colorPrimary: 'white',
                  hoverBorderColor: 'white',
                  colorText: 'white',
                  colorTextBase: 'white',
                  optionSelectedColor: 'white',
                  selectorBg: 'rgba(56, 78, 183, 0.3)',
                  optionSelectedBg: 'rgba(56, 78, 183, 0.3)',
                  multipleItemBg: 'rgba(56, 78, 183, 0.3)',
                  colorIcon: 'white',
                  colorIconHover: 'white'
                }
              },
              token: {
                colorBgBase: 'rgba(56, 78, 183, 0.3)',
                colorBgContainer: 'rgba(56, 78, 183, 0.3)',
                colorText: 'white',
                colorTextPlaceholder: 'white',
                colorIcon: 'white',
                colorIconHover: 'white'
              }
            }}
          >
            <div className="inputDark">
              <SelectAnt
                {...sharedProps}
                className="multiSelectAnt"
                placeholder="Estados Okid"
                value={formData['statusOkid']}
                onChange={value => {
                  handleChange('statusOkid', value)
                }}
              />
            </div>
          </ConfigProvider>
          <div className="inputDark">
            <AutoComplete
              label="Destinooo"
              options={optionsDestinations}
              onChangeText={handleChangeText}
              value={formData['destinationCode']}
              onChange={value => {
                handleChange('destinationCode', value)
                setSearchedValue(undefined)
              }}
            />
          </div>
          <div className="inputDark">
            <Select
              placeholder={'Marca'}
              options={brands.map(e => ({ value: e.name, label: e.name }))}
              value={formData['brand']}
              onChange={value => handleChange('brand', value)}
            />
          </div>
          <div className="inputDark">
            <Select
              placeholder={'Tipo'}
              options={Object.keys(typeQrEnumString).map(key => ({
                label: typeQrEnumString[key as keyof typeof typeQrEnumString],
                value: key
              }))}
              value={formData['type']}
              onChange={value => handleChange('type', value)}
            />
          </div>
        </div>
        <div className="containerButtonsFilters">
          <Button
            text="Buscar"
            icon={<FontAwesomeIcon style={{ marginRight: '10px' }} icon={faSearch} />}
            onClick={handleSearch}
          />
          <Button
            icon={<FontAwesomeIcon style={{ marginRight: '10px' }} icon={faEraser} />}
            text="Limpiar"
            onClick={handleClear}
          />
          <Button
            icon={<FontAwesomeIcon style={{ marginRight: '10px' }} icon={faFileExcel} />}
            text="Excel Reclusos"
            onClick={RecluseReport}
          />
          {data.data.length > 0 && (
            <Button
              icon={<FontAwesomeIcon style={{ marginRight: '10px' }} icon={faFileExcel} />}
              text="Generar Excel"
              onClick={() => onGenerate('excel')}
            />
          )}
          {data.data.length > 0 && (
            <Button
              icon={<FontAwesomeIcon style={{ marginRight: '10px' }} icon={faFilePdf} />}
              text="Generar PDF"
              onClick={() => onGenerate('pdf')}
            />
          )}
        </div>
      </div>
      <Table
        pagination={data}
        onPageChange={page => {
          setData(prevData => ({ ...prevData, currentPage: page }))
          onSearch(page)
        }}
        columns={showAProved}
        itemsPerPage={6}
        canAdd={false}
        Actions={Actions}
      />
      {user?.role === 'reception' && <FilesWachs />}

      <Modal
        isOpen={isModalVisible}
        styleContainer={{
          width: ' 100%',
          height: ' 100%',
          padding: '0px',
          background: 'rgb(0 0 0 / 0%)',
          overflow: 'hidden'
        }}
        styleContainerContent={{ padding: '0 60px', height: '100%', maxHeight: '100vh' }}
        onClose={handleModalClose}
      >
        <object
          data={pdfUrl}
          type="application/pdf"
          width="100%"
          height="99%" // Ajusta la altura según tus necesidades
        >
          <p>
            El archivo PDF no se puede visualizar.{' '}
            <a href={pdfUrl} target="_blank" rel="noopener noreferrer">
              Descargar PDF
            </a>
          </p>
        </object>
      </Modal>
    </div>
  )
}

export default ReportsPage
